import React from 'react'

export default function App() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white rounded-lg p-8 shadow-md">
        <h1 className="text-3xl font-semibold mb-4">🚧 Work in Progress 🚧</h1>
        <p className="text-gray-600 mb-8">
          The Wallet Connect Transfer feature is coming soon.
        </p>
      </div>
    </div>
  )
}
